<template>
  <div class="declaration-revenue-slide">
    <v-container>
      <v-row justify="center" class="header pa-4">
        <h1>Déclaration de revenus à partir de</h1>
      </v-row>
      <v-row justify="center">
        <p class="price">44.95$</p>
      </v-row>
      <v-row class="text pa-4" justify="center">
        <p>Pour la liste complète des services offerts:</p>
      </v-row>
      <v-row justify="center">
        <base-btn to="services" style="margin-left: 10px;">
          Services offerts
        </base-btn>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "DeclarationRevenue",
  data() {
    return {

    }
  }
}
</script>

<style scoped>
.declaration-revenue-slide {
  color: #2E6ADD;
}

.header {
  font-size: 30px;
  font-style: italic;
  text-align: center;
}

.text {
  color: #424242;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}

.price {
  color: #424242;
  font-weight: 800;
  font-size: 50px;
}

@media only screen and (max-width: 600px) {
  .header {
    font-size: 27px;
  }

  .text {
    font-size: 18px;
  }
}
</style>