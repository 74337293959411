<template>
  <div class="covid-19">
    <v-container class="pa-8">
      <v-row justify="center" class="header">
        <h1>COVID-19</h1>
      </v-row>
      <v-row class="text pt-7" justify="center">
        <p style="max-width: 75%; text-align: center;">Veuillez noter qu’en raison de la pandémie de COVID-19, il n'est actuellement pas possible de prendre rendez-vous en personne.</p>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "COVID19",
  data() {
    return {

    }
  }
}
</script>

<style scoped>
.covid-19 {
  color: red;
}

.header {
  font-size: 30px;
  font-style: italic;
  text-align: center;
}

.text {
  color: #424242;
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  padding-left: 20px;
}

@media only screen and (max-width: 600px) {
  .header {
    font-size: 27px;
  }

  .text {
    font-size: 20px;
  }
}
</style>