<template>
  <div class="impot-est-la">
    <v-container class="pa-8">
      <v-row justify="center" class="header">
        <h1>La saison des impôts est arrivée !</h1>
      </v-row>
      <v-row class="text-1 pt-7" justify="center">
        <p style="max-width: 75%; text-align: center;">N’oubliez pas de rassembler vos feuillets et reçus en lieu sûr afin de les avoir à portée de main lors de votre rendez-vous !</p>
      </v-row>
      <v-row class="text pa-4" justify="center">
        <p>Pour me joindre ou prendre rendez-vous:</p>
      </v-row>
      <v-row class="pa-4" justify="center">
        <base-btn to="contact" style="margin-left: 10px;">
          Prendre contact!
        </base-btn>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "ImpotEstLa",
  data() {
    return {

    }
  }
}
</script>

<style scoped>
.impot-est-la {
  color: #2E6ADD;
}

.header {
  font-size: 30px;
  font-style: italic;
  text-align: center;
}

.text-1 {
  color: #424242;
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  padding-left: 20px;
}

.text {
  color: #424242;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  padding-left: 20px;
}

@media only screen and (max-width: 600px) {
  .header {
    font-size: 27px;
  }

  .text-1 {
    font-size: 17px;
  }

  .text {
    font-size: 15px;
  }
}
</style>