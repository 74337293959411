<template>
  <v-sheet
    height="100%"
  >
    <v-row
      class="fill-height"
      align="center"
      justify="center"
    >
      <div>
        <v-container class="content">
          <block-content
            :blocks="data" 
            :serializers="serializers"
          />
        </v-container>
      </div>
    </v-row>
  </v-sheet>
</template>

<script>
import SlideContent from "@/components/SlideContent.vue";

export default {
  name: "SlideComponent",
  props: {
    data: Object
  },
  data() {
    return {
      serializers: {
        types: {
          slide: SlideContent
        }
      }
    }
  }
}
</script>

<style scoped>
.content {
  width: 500px;
}

@media (max-width: 500px) {
  .content {
    max-width: 350px;
    padding: 20px;
  }
}
</style>