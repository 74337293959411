<template>
  <div>
    <div class="slideTitle">
      {{title}}
    </div>

    <div class="slideDescription">
      <block-content
        :blocks="description" 
      />
    </div>

    <div v-if="cta_title" class="ctaButton">
      <base-btn :to="cta_url">
        {{cta_title}}
      </base-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "SlideContent",
  props: {
    title: String,
    description: Array,
    cta_title: String,
    cta_url: String
  }
}
</script>

<style scoped>
  .slideTitle {
    text-align: center;
    margin-bottom: 12px;
    font-weight: 700;
  }

 .slideTitle {
    font-size: 30px;
    color: #2e6add;
  }

.slideDescription {
  text-align: center;
  font-size: 20px;
}

.ctaButton {
  margin-top: 10px;
  text-align: center;
}
</style>