<template>
  <section
    id="homecarousel"
    class="primary"
  >
    <v-img
      :max-height="maxHeight"
      :src="require('@/assets/finance/home_background.jpg')"
      class="white--text"
      gradient="to right, rgba(255, 255, 255, .7), rgba(46, 106, 221, 0.2)"
    >
      <v-row :class="rowClass">
        <v-col cols=12 md=7>
          <v-container fill-height fluid>
            <v-row>
              <v-sheet
                min-height="350"
                width="100%"
                color="rgba(255, 255, 255, 0)"
              >
              <v-container>
                 <v-row class="pa-5">
                   <div class="hello-text">
                    {{ title }}
                   </div>
                   <div class="hello-subtext">
                    {{ description }}
                   </div>
                   <v-row style="margin-top: 30px;">
                     <v-col cols=12 md=3>
                       <base-btn to="services">
                        Services offerts
                      </base-btn>
                     </v-col>
                     <v-col cols=12 md=3>
                       <base-btn to="contact">
                        Contact
                      </base-btn>
                     </v-col>
                   </v-row>
                 </v-row>
              </v-container>
              </v-sheet>
            </v-row>
          </v-container>
        </v-col>
        <v-col cols=12 md=5>
          <v-container fill-height fluid>
            <v-carousel
              :height="this.$vuetify.breakpoint.lgAndUp ? '350px' : '450px'"
              hide-delimiter-background
              show-arrows-on-hover
              cycle
              :interval="10000"
              progress-color="#2E6ADD"
              light
            >
              <v-carousel-item
                v-for="(slideData, i) in allSlides"
                :key="i"
              >
                <SlideComponent 
                  :data="slideData"
                />
              </v-carousel-item>
            </v-carousel>
          </v-container>
        </v-col>
      </v-row>
    </v-img>
  </section>
</template>

<script>
import ImpotEstLa from "@/components/slides/ImpotEstLa.vue";
import DeclarationRevenue from "@/components/slides/DeclarationRevenue.vue";
import COVID19 from "@/components/slides/COVID19.vue";

import SlideComponent from "@/components/Slide.vue";

import sanity from "@/client";

const query = `*[_type == "homepage"]{
  _id,
  title,
  description
}[0]`;

const slidesQuery = `*[_type == "slide"]{
  _id,
  _type,
  title,
  description,
  cta_title,
  cta_url
}`;

  export default {
    name: 'HomeCarousel',
    components: {
      SlideComponent
    },
    created() {
      this.fetchData();
      this.fetchSlidesData();
    },
    computed: {
      rowClass() {
        return this.$vuetify.breakpoint.lgAndUp ? 'pt-16' : '';
      },
      maxHeight() {
        const height = this.$vuetify.breakpoint.mdAndUp ? '600px' : '100%';

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
    data: () => ({
      carouselBackgroundColor: 'rgba(255, 255, 255, 0.7)',
      title: "",
      description: "",
      slides: [
        'First',
        'Second',
        'Third',
        'Fourth',
        'Fifth',
      ],
      slides2: [
        ImpotEstLa,
        DeclarationRevenue,
        COVID19,
      ],
      allSlides: []
    }),
    methods: {
      fetchData() {
        sanity.fetch(query).then(
          (data) => {
            this.title = data.title;
            this.description = data.description;
          },
          (error) => {
            console.log(error);
          }
        );
      },

      fetchSlidesData() {
        sanity.fetch(slidesQuery).then(
          (data) => {
            this.allSlides = data;
          },
          (error) => {
            console.log(error);
          }
        );
      }
    }
  }
</script>

<style scoped>
.hello-text {
  margin-top: 30px;
  font-weight: 800;
  font-size: 50px;
  color: #2E6ADD
}

.hello-subtext {
  color: #424242;
  font-size: 30px;
  font-weight: 600;
}
</style>